export const typeParcel = [
    { value: "AV", label: "Á vista" },
    { value: "CO", label: "Comissão Corretor" },
    { value: "COR", label: "Comissão Corretor (Repasse)" },
    { value: "CH", label: "Comissão Interna" },
    { value: "CHR", label: "Comissão Interna (Repasse)" },
    { value: "DS", label: "Desconto" },
    { value: "SI", label: "Entrada" },
    { value: "FG", label: "FGTS" },
    { value: "FB", label: "Financiamento Bancário" },
    { value: "IT", label: "Intermediaria"},
    { value: "PC", label: "Parcela de chaves"},
    { value: "PM", label: "Parcela Mensal" },
    { value: "AN", label: "Parcela Anual" },
    { value: "TR", label: "Parcela Trimestral" },
    { value: "SM", label: "Parcela Semestral" },
    { value: "SB", label: "Subsídio" }
];

export const markParcelPaid = [
    { value: "PAID_MANUAL", label: "Pago e compensado (manual)" },
    { value: "PAID_MANUAL_COMPENSACAO", label: "Pago e compensado (manual)" },
    { value: "PAID_MANUAL_SEM_COMPENSACAO", label: "Pago sem compensação (manual)" },
    { value: "PAID_AUTOMATIC", label: "Pago e compensado" },
    { value: "PAID_RETORNO", label: "Pago e compensado" }
    
];

export const statusContract = [
    { value: "", field:'status', label: "Todos" },
    { value: "1", field:'status', label: "Ativo" },
    { value: "2", field:'status', label: "Cancelado" },
    { value: "3", field:'status', label: "Distratado com acordo" },
    { value: "4", field:'status', label: "Distratado sem acordo" },
    { value: "5", field:'status', label: "Processo de distrato" },
];