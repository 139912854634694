
import axios from "axios";
import { IClient } from "../interface/I_Client";
import { AuthType } from "../interface/I_General";
import { IAssociatedBillingInstallment, IDetailsInstallment, IPayments } from "../interface/I_Payments";

// URLs
const API_URL = process.env.REACT_APP_API_URL


// recupera lista de pagamentos todas as parcelas
export async function getListPayments() 
{
    try {
        const response = await axios.get<IPayments>(`${API_URL}/module/getListPayments`, {});
        // Tratar a resposta aqui
        console.log("Lista de todos os pagamentos:", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar lista de todos os pagamentos:", error);
        throw error;
    }

}

// recupera lista de pagamentos todas as parcelas
export async function getAssociatedBilling(id:string | number) 
{
    try {
        const response = await axios.get<IAssociatedBillingInstallment>(`${API_URL}/module/getAssociatedBilling`, {});
        // Tratar a resposta aqui
        console.log("Dados cobrança vinculo a parcela: ", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}



// recupera lista de pagamentos todas as parcelas
export async function detailsParcel(id:string | number) 
{
    try {
        const response = await axios.get<IDetailsInstallment>(`${API_URL}/module/getDetailsParcel`, {});
        // Tratar a resposta aqui
        console.log("Dados da parcela: ", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}


// recupera lista de pagamentos todas as parcelas
export async function getDataClient(id:string | number) 
{
    try {
        const response = await axios.get<IClient>(`${API_URL}/module/getDataClient`, {});
        // Tratar a resposta aqui
        console.log("Dados da parcela: ", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}

// faz o downlaod em excel dos pagamentos
export async function downloadBoletos(link_boleto :string) 
{
    try {

        window.open(link_boleto);
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}

// faz o downlaod em excel dos pagamentos
export async function downloadExcel( auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.get(`${API_URL}/module/token/download`,{ headers }
                        );

        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar dados da parcela: ", error);
        throw error;
    }

}

// envia dados do filtro para download
export async function sendFilterdownloadExcel(form:any, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
                                    //   `${API_URL}/report/parcels/download?${queryParams}`
        const response = await axios.post(`${API_URL}/module/report/parcels/download`,
                            { form },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}
