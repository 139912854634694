import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import flattenChildren from "react-keyed-flatten-children";
import Access from "./Access";
import Create from "./Create";
import Edit from "./Edit";
import Delete from "./Delete";
import List from "./List";
import Import from "./Import";
import Export from "./Export";
import Dev from "./Dev";

import { permissionsUser } from "../../../../app/request/permission";

// import useNavegationZustand from "../../../../app/zustand/NavegationZustand";
import { PermissionsProps, ChildComponentProps } from "../../../../app/interface/I_Permission";
import Retorno from "./Retorno";
import Remessa from "./Remessa";
import Spreadsheet from "./Spreadsheet";
import useNavegationZustand from "../../../../app/zustand/NavegationZustand";
import usePermissionStore from "../../../../app/zustand/PermissionStore";
import Approval from "./Approval";

const ComponentPermission: React.FC<PermissionsProps> & {
    Access: React.FC<ChildComponentProps>;
    Create: React.FC<ChildComponentProps>;
    Edit: React.FC<ChildComponentProps>;
    Delete: React.FC<ChildComponentProps>;
    List: React.FC<ChildComponentProps>;
    Import: React.FC<ChildComponentProps>;
    Export: React.FC<ChildComponentProps>;
    Retorno: React.FC<ChildComponentProps>;
    Remessa: React.FC<ChildComponentProps>;
    Spreadsheet: React.FC<ChildComponentProps>;
    Approval: React.FC<ChildComponentProps>;
    Dev: React.FC<ChildComponentProps>;
} = ({ children, ...props }) => {
    const { renderType, className, includeVerify } = props;

    const permissionsStore = usePermissionStore();
    const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

    useEffect(() => {        
        setAllowedPermissions(permissionsStore.permissions);
    }, [permissionsStore.permissions, allowedPermissions]);

    const hasWildcardMatch = (equalVerify?:string) => {

        // Verifica se há alguma string no array que contém '.*' e se a parte antes de '.*' corresponde a permissionsStore.currentRoute
        return  allowedPermissions.some(permission => {
            
            if (permission.includes('.*'))
            {
                const [prefix] = permission.split('.*');
                if(!!equalVerify)
                {
                    return equalVerify === prefix;
                }

                return permissionsStore.currentRoute === prefix;
            }

            return false;
        });
    }

    const isAllowed = (require: string, equalVerify:any) => {        

        if(!!equalVerify)
        {            
            if(hasWildcardMatch(equalVerify)) return true
            return require && allowedPermissions.includes(`${equalVerify}.${require}`);
        }

        if(hasWildcardMatch()) return true
 
        return require && allowedPermissions.includes(`${permissionsStore.currentRoute}.${require}`);
    };

    const processChild = (child: ReactNode): ReactNode | null => {
        if (React.isValidElement(child))
        {
            const elementType = child.type as any;
            const equalVerify = child.props && typeof child.props === 'object' && child.props.equalVerify !== undefined && Object.keys(child.props.equalVerify).length !== 0
                                ? child.props.equalVerify
                                : undefined;
        
            if (elementType === 'div') {
                // Processa os filhos aninhados
                const flattenedChildren = flattenChildren(child.props.children);
        
                const hasAllowedChild = flattenedChildren.some(nestedChild => {
                    if (React.isValidElement(nestedChild)) {
                        const nestedType = nestedChild.type as any;
                        const equalVerifyChildren = nestedChild.props && typeof nestedChild.props === 'object' && nestedChild.props !== null && 'equalVerify' in nestedChild.props &&
                                                    nestedChild.props.equalVerify !== undefined  && Object.keys(nestedChild.props.equalVerify as object).length !== 0
                                                        ? nestedChild.props.equalVerify
                                                        : undefined;

                        const permissionType = nestedType && nestedType.name ? nestedType.name.toLowerCase() : undefined;
                        
                        return permissionType && isAllowed(permissionType, !!equalVerifyChildren ? equalVerifyChildren : equalVerify);
                    }
                    return false;
                });
        
                if (hasAllowedChild)
                {
                    // type casting para resolver o problema de tipagem
                    return React.cloneElement(child, { isallowed: 'true' } as any);
                }
            } else { 
                // Se não for uma div, verifica se tem uma propriedade 'name'
                const permissionType = elementType && elementType.name ? elementType.name.toLowerCase() : undefined;
        
                if (permissionType && isAllowed(permissionType, equalVerify))
                {
                    // type casting para resolver o problema de tipagem
                    return React.cloneElement(child, { isallowed: 'true' } as any);
                }
            }
        }    
        return null;
    };  
    

    const processChildren = (children: React.ReactNode): React.ReactNode => {
        return React.Children.map(children, processChild);
    };
    
    const processedChildren = processChildren(children);

    if (renderType)
    {
        switch (renderType)
        {
            case "disabled":
                return (
                    <div className={className ? className : ''} style={{ cursor: 'not-allowed' }}>
                        <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                            {processedChildren}
                        </div>
                    </div>
                );
            case "remove":
                return null;
            default:
                return (
                    <div className={className ? className : ''} style={{ cursor: 'not-allowed' }}>
                        <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                            {processedChildren}
                        </div>
                    </div>
                );
        }
    } else {
        return <>{processedChildren}</>;
        // return (processedChildren ? <div>{processedChildren}</> : <></>)
    }
};

ComponentPermission.Access = Access;
ComponentPermission.Create = Create;
ComponentPermission.Edit = Edit;
ComponentPermission.Delete = Delete;
ComponentPermission.List = List;
ComponentPermission.Import = Import;
ComponentPermission.Export = Export;
ComponentPermission.Retorno = Retorno;
ComponentPermission.Remessa = Remessa;
ComponentPermission.Spreadsheet = Spreadsheet;
ComponentPermission.Approval = Approval;
ComponentPermission.Dev = Dev;

export default ComponentPermission;
