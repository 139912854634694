
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../../helpers";
import Select from "react-select";
import { FormContractProps, IFormContract } from "../../../../../app/interface/I_Contract";
import { useAuth } from "../../../../../app/modules/auth";
import { TagifyCustom } from "../../../../layout/components/buttons/TagifyCustom";
import * as Yup from 'yup'
import {schemaContract} from "./SchemaForm"; 
import { getAllProduct } from "../../../../../app/request/product";
import { useMemo } from 'react';
import { getUnitsByIdProduct } from "../../../../../app/request/unit";
import { editContractAPI } from "../../../../../app/request/contract";
import Swal from "sweetalert2";
import { statusContract } from "../../../../../app/Constants";
import { optionsPeriodicidade, optionsReadjustmentIndex } from "../../../../../app/globals/OptionsGlobals";
import { NumericFormat } from "react-number-format";

export const FormEditContract: React.FC<FormContractProps> = ({ 
    formValues,
    closeModal, 
    onFormSubmit
}) => {    
    
    const {auth, logout, setCurrentUser} = useAuth();

    interface Option {
        value: string;
        label: string;
    }

    interface OptionSelected {
        id_product: string;
        id_unit: string;
        status: string;
        readjustment_index: string;
        readjustment_check: string;
        readjustment_application: string;
    }

    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);
    const [optionsUnit, setOptionsUnit] = useState<Option[]>([]);

    // seta conforme a verificação
    const [nameClient, setNameClient] = useState('');
    // const [nameCorretor, setNameCorretor] = useState('');

    const [nameUnit, setNameUnit] = useState('');

    const [formData, setFormData] = useState<IFormContract >(formValues || {
        id: '0',
        id_client: '0',
        id_product: '0',
        id_unit: '0',
        id_corretor: '',
        name: '',
        name_product: '',
        name_unit: '',
        name_corretor: '',
        status: '',
        description: '',
        rate:'',
        readjustment_index:'',
        readjustment_check:'',
        readjustment_application:'',
        contract_date:'',
        mora:'',
        multa:''
    });

    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({        
        id_client: '',
        id_product: '',
        id_unit: '',
        id_corretor: '',
        description:''
    });
    
    useEffect(() => {
        fetchProducts();        
    }, []);
    
    useEffect(() => {
        if (formValues)
        {
            setFormData((prevState) => ({
                ...prevState,
                id: formValues.id || '0',
                id_client: formValues.id_client || '0',
                id_product: formValues.id_product || '0',
                id_unit: formValues.id_unit || '0',
                id_corretor: formValues.id_corretor || '',
                name: formValues.name || '',
                name_product: formValues.name_product || '',
                name_unit: formValues.name_unit || '',
                name_corretor: formValues.name_corretor || '',
                status: formValues.status || '',
                description: formValues.description || '',
                rate: formValues.rate || '',
                readjustment_index: formValues.readjustment_index || '',
                readjustment_check: formValues.readjustment_check || '',
                readjustment_application: formValues.readjustment_application || '',
                contract_date: formValues.contract_date || '',
                mora: formValues.mora || '',
                multa: formValues.multa || '',
            }));
            
            fetchUnits(formValues.id_product)
        }
        
    }, [formValues]);

    useEffect(() => {

        if (formData.name && formData.name.split('-').length > 0) {
            setNameClient(formData.name.split('-')[0]);
        } else {
            setNameClient(formData.name);
        }

      }, [formData.name]);

    const handleContractChange = useCallback((e:any) => {

        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,            
            [name]: value
        }));
    }, []);

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";
        const textSelected = selectedOption ? selectedOption.label : "";

        if(fieldName === 'id_product')
        {            
            fetchUnits(value);

            setFormData(prevState => ({
                ...prevState,                
                [fieldName]: value.toString(),
                ['name_product']: textSelected
            }));

        }else if(fieldName === 'id_unit'){
            
            setFormData(prevState => ({
                ...prevState,
                [fieldName]: value.toString(),
                ['name_unit']: textSelected,
                name:`${nameClient} - ${textSelected}`
            }));

            if(!!textSelected)
            setNameUnit(textSelected);
        }else{
            setFormData(prevState => ({
                ...prevState,
                [fieldName]: value.toString(),
            }));
        }
    };
    
    const handleInputChange = (
        value: string|number,
        fieldName: keyof IFormContract
      ) => {

        setFormData(prevState => ({
            ...prevState,            
            [fieldName]: value
        }));

        
    };

    const fetchProducts = async () => {
        if (auth && auth.api_token)
        {
            const response = await getAllProduct(auth);
          
            if (response && response.data && Array.isArray(response.data))
            {
                const newOptionsProduct = response.data.map((product: { id: any; name: any; }) => ({
                    value: product.id.toString(),
                    label: product.name,
                }));

                setOptionsProduct(newOptionsProduct);
            }
        }
    };

    const fetchUnits = async (id:string) => {
        if (auth && auth.api_token)
        {
            const response = await getUnitsByIdProduct(id, auth);
            
            if (response && response.data && Array.isArray(response.data))
            {
                const newOptionsUnit = response.data.map((unit: { id: any; name: any; }) => ({
                    value: unit.id.toString(),
                    label: unit.name,
                }));
        
                setOptionsUnit(newOptionsUnit);
            }
        }
    };
    
    function returnForm()
    {
        if(formData.id !== '0')
        {
            if (typeof onFormSubmit === 'function')
            {
                onFormSubmit(formData);
            }
        }
    }
    
    // recebe o valor do cliente
    const onSelectTagify = (e:any) => {
        setNameClient(e.target.innerText);

        setFormData(prevState => ({
            ...prevState,
            name:`${e.target.innerText} - ${nameUnit || prevState.name_unit}`
        }));

        handleContractChange(e)
    }

      // recebe o valor do cliente
    const onSelectTagifyCorretor = (e:any) => {
        handleContractChange(e)
    }

    // envia formulario
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        try {

            await schemaContract.validate(formData, { abortEarly: false });
            setFormErrors({});

            await Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja salvar este contrato?",
                showCancelButton: true,
                confirmButtonText: 'Sim, salvar!',
                cancelButtonText: 'Não',
            }).then((result) => {
                
                if (result.isConfirmed)
                {    
                    saveForm();                    
                }
            });            

        } catch (errors) {

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }        
    };

    const saveForm =  async() => {

        if( auth && auth.api_token)
        {
            const response = await editContractAPI(formData, auth);
            
            if(response.data.success)
            {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Contrato salvo com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });

                if(response.data.data !== "0" && response.data.data !== null && response.data.data !== "" )
                {
                    let lastId = response.data.data.toString();  

                    setFormData(prevState => ({
                        ...prevState,                
                        ['id']: lastId
                    }));
                    
                    returnForm();
                }

            }else{
                if(response.data.status !== 403)
                {
                    Swal.fire({
                        icon: "info",
                        title: "Atenção",
                        html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                        showCancelButton: false,
                        confirmButtonText: 'Entendi',
                    });
                }
            }
        }
    }

    console.log('editar modal contrato ', formData)
   
    return (
            <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal ? "": "show"}`} id="modalEditContract" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal ? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                            <div className="container-fluid">
                                <div className="d-block">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                            <form onSubmit={handleSubmit}>
                                                {/* Contrato */}
                                                <div className="separator d-flex flex-center">
                                                    <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Contrato</span>
                                                </div>
                                                <div className="pe-12 me-n12 mt-10" >
                                                    <div className="row gy-5">
                                                        {/* produto */}
                                                        <div className="col-lg-6  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Produto</label>
                                                            <div className=''>
                                                                <Select
                                                                    name="id_product"
                                                                    className="w-auto"
                                                                    placeholder="Selecione um Produto"
                                                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                                                    options={optionsProduct}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                                                />
                                                                {formErrors.id_product && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_product}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* Unidade */}
                                                        <div className="col-lg-6  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Unidade</label>
                                                            <div className=''>
                                                                <Select
                                                                    name="id_unit"
                                                                    className="w-auto"
                                                                    placeholder="Selecione uma Unidade"
                                                                    value={formData.id_unit !== '0' && formData.id_unit !== '' ? { value: formData.id_unit, label: optionsUnit.find(option => option.value === formData.id_unit)?.label } : null}
                                                                    options={optionsUnit}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_unit")}
                                                                />
                                                                {formErrors.id_unit && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_unit}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Cliente */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Cliente</label>
                                                            <div className=''>
                                                                <TagifyCustom
                                                                    onSelectTagify={onSelectTagify}
                                                                    selectedId={formData.id_client}
                                                                    selectedName={
                                                                        formData.name && formData.name.includes('-')
                                                                        ? formData.name.split('-')[0]
                                                                        : formData.name
                                                                    }
                                                                    endPoint="client"
                                                                />
                                                                {formErrors.id_client && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_client}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* Corretor */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Corretor</label>
                                                            <div className=''>
                                                                <TagifyCustom
                                                                    onSelectTagify={onSelectTagifyCorretor}
                                                                    selectedId={formData.id_corretor}
                                                                    selectedName={
                                                                        formData.name_corretor && formData.name_corretor.includes('-')
                                                                        ? formData.name_corretor.split('-')[0]
                                                                        : formData.name_corretor
                                                                    }
                                                                    endPoint="corretor"
                                                                />
                                                                {formErrors.id_corretor && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_corretor}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Status do contrato */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Status do contrato</label>
                                                            <div className=''>
                                                                <Select
                                                                    name="cont"
                                                                    className="w-auto"
                                                                    placeholder="Selecione um status"
                                                                    value={{ value: formData.status, label: statusContract.find(option => option.value === formData.status)?.label }}
                                                                    options={statusContract}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "status")}
                                                                />
                                                                {formErrors.status && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.status}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        

                                                        {/* Juros padrao contrato */}
                                                        <div className="col-lg-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Juros Padrão Contrato</label>
                                                            <div className=''>
                                                                <NumericFormat
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    prefix=""
                                                                    suffix=" %"
                                                                    thousandsGroupStyle="thousand"
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    allowLeadingZeros={true}
                                                                    className={`form-control`} 
                                                                    name="rate"
                                                                    value={formData.rate || '0,00'}
                                                                    onValueChange={(values) => {
                                                                        handleInputChange(values.floatValue || 0, "rate");
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        {/* Juros padrao contrato */}
                                                        <div className="col-lg-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Juros Mora</label>
                                                            <div className=''>
                                                                <NumericFormat
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    prefix=""
                                                                    suffix=" %"
                                                                    thousandsGroupStyle="thousand"
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    allowLeadingZeros={true}
                                                                    className={`form-control`} 
                                                                    name="mora"
                                                                    value={formData.mora || '0,00'}
                                                                    onValueChange={(values) => {
                                                                        handleInputChange(values.floatValue || 0, "mora");
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                        
                                                        {/* Multa */}
                                                        <div className="col-lg-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Multa</label>
                                                            <div className=''>
                                                                <NumericFormat
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    prefix=""
                                                                    suffix=" %"
                                                                    thousandsGroupStyle="thousand"
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    allowLeadingZeros={true}
                                                                    className={`form-control`} 
                                                                    name="multa"
                                                                    value={formData.multa || '0,00'}
                                                                    onValueChange={(values) => {
                                                                        handleInputChange(values.floatValue || 0, "multa");
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        {/* Data do Contrato */}
                                                        <div className="col-lg-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Data do Contrato</label>
                                                            <div className=''>
                                                            <input 
                                                                type="date" 
                                                                className={`form-control`} 
                                                                name="contract_date"
                                                                value={formData.contract_date || ''}
                                                                onChange={(e) => {
                                                                        handleInputChange(e.target.value, "contract_date")
                                                                    }
                                                                }
                                                            />
                                                            </div>
                                                        </div>

                                                        {/* Indice de correção monetária */} 
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Indice de correção monetária</label>
                                                            <div className=''>
                                                                <Select
                                                                    name="readjustment_index"
                                                                    className="w-auto"
                                                                    placeholder="Selecione um indice"
                                                                    value={formData.readjustment_index !== '0' && formData.readjustment_index !== '' ? { value: formData.readjustment_index, label: optionsReadjustmentIndex.find(option => option.value === formData.readjustment_index)?.label } : null}
                                                                    options={optionsReadjustmentIndex}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "readjustment_index")}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* Indice de correção monetária */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Forma de apuração do reajuste</label>
                                                            <div className=''>
                                                                <Select
                                                                    name="readjustment_check"
                                                                    className="w-auto"
                                                                    placeholder="Selecione uma apuração"
                                                                    value={formData.readjustment_check !== '0' && formData.readjustment_check !== '' ? { value: formData.readjustment_check, label: optionsPeriodicidade.find(option => option.value === formData.readjustment_check)?.label } : null}
                                                                    options={optionsPeriodicidade}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "readjustment_check")}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* Forma de aplicação do reajuste  */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Forma de aplicação do reajuste </label>
                                                            <div className=''>
                                                                <Select
                                                                    name="readjustment_application"
                                                                    className="w-auto"
                                                                    placeholder="Selecione uma aplicação"
                                                                    value={formData.readjustment_application !== '0' && formData.readjustment_application !== '' ? { value: formData.readjustment_application, label: optionsPeriodicidade.find(option => option.value === formData.readjustment_application)?.label } : null}
                                                                    options={optionsPeriodicidade}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "readjustment_application")}
                                                                />
                                                            </div>
                                                        </div>
                                                        

                                                        {/* informações adicioanis */}
                                                        <div className="col-lg-12  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Informações adicionais</label>
                                                            <div className=''>
                                                                <input
                                                                    name="description"
                                                                    className="form-control"
                                                                    value={formData.description || '' }
                                                                    onChange={handleContractChange}
                                                            
                                                                />
                                                                {formErrors.description && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.description}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal-footer border-0">
                                                    <button type="button" id="btn_close" className="btn btn-light btn-sm" onClick={closeModal}>Fechar</button>
                                                    <button type="submit" className="btn btn-success me-4 btn-sm">
                                                    {/* <button type="submit" className="btn btn-success me-4" onClick={handlerSaveForm}> */}
                                                        Salvar
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}