import React, { useMemo, useState, useEffect, InputHTMLAttributes, useRef } from "react";
import { useRowState, UseRowStateLocalState, Hooks, useTable, useSortBy, usePagination,  TableToggleAllRowsSelectedProps, useRowSelect, Column, Row, IdType, TableExpandedToggleProps, TableToggleRowsSelectedProps } from "react-table";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { TableProps } from "../../../../app/interface/I_Table";
import { KTSVG, toAbsoluteUrl } from "../../../helpers"; 
import { Link } from "react-router-dom";
import { interfaceMap, functionMap } from "../../../helpers/functions/tables/General";
import { useAuth } from "../../../../app/modules/auth";
import Swal from "sweetalert2";
import Select from "react-select";
import { ItemTypeParcel } from "../../../../app/types/T_Parcel";
import { useSelector } from 'react-redux';
import { isDateExpired } from "../../../helpers/functions/dates/date";
import clsx from "clsx";
import { LoadAnimation } from "../../../helpers/functions/swal/LoadAnimation";

import BaseServiceHttp from "../../../helpers/functions/http/BaseServiceHttp";
import { useDispatch } from "react-redux";
import { setPermission } from "../../../../app/redux/api/permission/permission";


import useNavegationZustand from "../../../../app/zustand/NavegationZustand";
import ComponentPermission from "../permissions/ComponentPermission";

type Partial<T> = {
    [P in keyof T]?: T[P];
};

interface IRow {
    [key: string]: any;
}

interface IPropops {
    gotoPage: (go:number) => void;
    previousPage: () => void;
    nextPage: () => void;
    page:any;
    totalCount:number;
    pageCount:number;
    disabledPrevious:string;
    disabledNext:string;
}

const TableAdvanced = (
        { 
            options: { 
                id, 
                header, 
                iInterface,  
                endPoint, 
                methodEndPoint, 
                title,
                classHeadTd,
                classtbodyTr,
                isHidePaginate, // se será exibido ou não a paginação
                funDeleteInMass, // funcao a ser chamada em caso de delete em massa
                funChangeInMass, // funcao a ser chamada em caso de alteração em massa
                textDelete, // texto para quanod for deletar exibir corretamente
                // updateTableData
                classTbodyTd,
                isHideChekbox,
                download,
                verifyPermission
            },
            tableData,
            optionsActionInMassa,
            handleInsert,
            reloadTable
    }: TableProps): JSX.Element => {
    const [loading, setLoading] = useState(false);

    const [resultFetch, setResultFetch] = useState({})

    
    const interfaceDinamic = interfaceMap[iInterface];
    type typeInterfaceDinamic = typeof interfaceDinamic;
    
    const [data, setData] = useState<typeInterfaceDinamic[]>([]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [changeInMass, setChangeInMass] = useState<{ value: string, field: string, label:string }[]>([{ value: "", field:'', label: "Nenhum" }]);
    const [nameIntegration, setNameIntegration] = useState({countApiBoletoCloud: 0, countItauBank: 0});
    const [totalCount, setTotalCount] = useState(0);
    const [checkedRow, setChekedRow] = useState(false);

    const [propsPaginate, setProposPaginate] = useState<IPropops>();    
    
    const {auth, logout, setCurrentUser} = useAuth();

    
	const filterForm = useSelector((state:any) => state.filter);
    
	const filterGlobalProduct = useSelector((state:any) => state.productSlice);

    /**
     * Inicio cria um componente Checkbox e  resolve problema com inderteminate
     */
  
    const IndeterminateCheckbox = React.forwardRef<HTMLInputElement,{ indeterminate?: boolean } & InputHTMLAttributes<HTMLInputElement>>
        (({ indeterminate, checked, onChange, ...rest }, ref) => {
            const defaultRef = React.useRef<HTMLInputElement>(null);
            const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>;

            React.useEffect(() => {
                if (resolvedRef && resolvedRef.current) {
                    resolvedRef.current.indeterminate = indeterminate ?? false;
                    resolvedRef.current.checked = checked ?? false;
                }
            }, [resolvedRef, indeterminate, checked]);

            const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                  onChange(event);
                }
            };

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} onChange={handleChange}/>
                </>
            );
        }
    );

    /**
     * Fim checkBox
     */

    const API_URL = process.env.REACT_APP_API_URL
  
    const columns = React.useMemo(
        () =>

        header.map((header) => ({
            Header: header.Header,
            accessor: header.accessor as keyof typeInterfaceDinamic,
          })),
        [header]
    );

    const handlerSelectedRow = (row: Row<typeInterfaceDinamic>, e:any) => {
        const rowId: any = row.original.id.toString();
        
        setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.includes(rowId);
            if (row.original.name_integration === 'api_cloud' || row.original.name_integration === 'itau_bank') {
                setNameIntegration((prevState) => ({
                    ...prevState,
                    countApiBoletoCloud: row.original.name_integration === 'api_cloud' ? e.target.checked ? prevState.countApiBoletoCloud + 1 : Math.max(0, prevState.countApiBoletoCloud - 1) : prevState.countApiBoletoCloud,
                    countItauBank: row.original.name_integration === 'itau_bank' ? e.target.checked ? prevState.countItauBank + 1 : Math.max(0, prevState.countItauBank - 1) : prevState.countItauBank,
                }));
            }

            if (isSelected)
            {
                // Se a linha já estiver selecionada, remova-a do array de seleção
                return prevSelectedRows.filter((selectedId) => selectedId !== rowId);
                
            } else {
                // Se a linha não estiver selecionada, adicione-a ao array de seleção
                return [...prevSelectedRows, rowId];
            }
            
        });        

    }
    

    /* funcao que pede confirmação antes de executar a mudança em massa*/
    const handlerConfirmeChangeInMass = () => {
        
        if( !!changeInMass[0].value )
        {

            if(changeInMass[0].value === 'Boleto')
            {
                Swal.fire({
                    // title: 'Atenção',
                    icon: 'info',
                    html:`<div><p style="padding: 3px;"> <span style="background: #f5f0ff;padding: 3px;border-radius: 3px;">Você selecionou <strong><u>${changeInMass[0].value}</u></strong>, deseja gerar um boleto para esta parcela?<span></p></div>
                    <p>
                        <div style="text-align: center;">
                            <strong>Atenção!</strong>
                        </div>
                    </p>

                    <p>
                        <div style="text-align: justify;">
                            Somente boletos do banco <strong><u>Itaú</u></strong> sofrerão as alterações de forma simultânea no banco via API. 
                            Todos os outros deverão ser alterados também no aplicativo do respectivo banco. 
                        </div>
                    </p>
                    <p>
                        <div style="text-align: justify;">
                            Obs.: Ao lado de cada parcela com boleto gerado, segue também a bandeira do banco de origem, favor se atentar. 
                        </div>
                    </p>
                    `,
                    width: '800px',
                    showCloseButton: true,
                    showCancelButton: false,
                    showDenyButton:true,
                    focusConfirm: false,
                    confirmButtonText:'Sim, gerar boleto',
                    denyButtonText: 'Alterar apenas'
                    // cancelButtonText:'Não'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed)
                    {
                        LoadAnimation('Estamos processando...');
                        handlerMass('sim');
                        setChangeInMass([{value: "", field:'', label: "Nenhum" }]);
                    }else if (result.isDenied)
                    {             
                        LoadAnimation('Estamos processando...');
                        handlerMass('nao');           
                        setChangeInMass([{value: "", field:'', label: "Nenhum" }]);
                    }
                })
            }else{
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Deseja realizas mudança em massa nas linhas selecionadas?",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Sim, continuar!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed)
                    {
                        LoadAnimation('Estamos processando...');
                        handlerMass();
                        setChangeInMass([{value: "", field:'', label: "Nenhum" }]);
                    }else{
                        
                        setChangeInMass([{value: "", field:'', label: "Nenhum" }]);
                    }
                });
            }


            
        }
        
    }
    
    useEffect(() => {
        handlerConfirmeChangeInMass();
    }, [changeInMass]);

    /* realiza mudança em massa*/
    const handlerMass = async (boleto:any = null) => {
        
        if (auth && auth.api_token)
        {
            const functionNameChange = funChangeInMass;
            if (functionNameChange && functionNameChange in functionMap)
            {
                const funChange = functionMap[funChangeInMass as keyof typeof functionMap];
                if (funChange)
                {              
                    // significa que os valores setados estava realcioandaos a parcelas e se deve gerar ou nao boleto
                    if(!!boleto)
                    {
                        changeInMass[1] = {
                            "value": boleto,
                            "field": "",
                            "label": "gerar_boleto"
                        }
                    }      
                    const response = await funChange(selectedRows, changeInMass, auth);
                    
                    if(response.data.status && response.data.status !== 403)
                    {                        
                        Swal.fire({
                            icon: "info",
                            title: "Atenção",
                            html: `Não foi possível alterar as  linhas selecionadas, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                            showCancelButton: false,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Entendi',
                        });                      
                        
                    }else if(response.data.response && response.data.response.hasOwnProperty('success') && response.data.response.success)
                    {                        
                        updateSelectedRows();
                        Swal.fire({
                            icon: "success",
                            title: "Parabéns!",
                            text: `${selectedRows.length || ''} Linhas alteradas com sucesso.`,
                            showCancelButton: false,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Entendi',
                        });

                    }
                    
                    setSelectedRows([]);
                    var checkboxes = document.getElementsByClassName("form-check-input");
                    for (var i = 0; i < checkboxes.length; i++)
                    {
                        var checkbox = checkboxes[i] as HTMLInputElement;
                        checkbox.checked = false;
                        
                    }
                }
            }
        }

    }

    /** aplica atualização nas linhas da tabela apos edição em massa */
    const updateSelectedRows = () => {
        
        setSelectedRows((prevSelectedRows: string[]) => {
            return prevSelectedRows.map((rowId) => {
                const row = data.find((item) => item.id.toString() === rowId);

                if (row)
                {
                    const updatedValues: { [key: string]: any } = {};
                    changeInMass.forEach((field) => {

                        // field.field -> contem nome da propriedade equivalente a propriedade do objeto da tabela
                        // field.value -> contem o novo valor que a propriedade irá receber
                        if(iInterface === 'contract')
                        {
                            updatedValues['situation'] = field.label;
                        }else{
                            
                            updatedValues[field.field] = field.value;
                        }
                    });

                    const updatedRow = { ...row, ...updatedValues };

                    setData((prevData) => {
                        const updatedData = prevData.map((item) => {
                            if (item.id === row.id)
                            {
                                return updatedRow;
                            }
                            return item;
                        });
                        return updatedData;
                    });

                    // return updatedRow;
                }
                return rowId;
            });
        });
    };

    /* funcao que pede confirmação antes de deletar em massa*/
    const handlerConfirmeDelete = () => {

        let texto_delete_cloud = ``;
        let texto_delete_itau_bank = ``;
        let texto_atencao = ``;
        let texto_principal = ``;
        let texto_observacao = ``;
        let ul = ``;
        if(nameIntegration.countApiBoletoCloud > 0 || nameIntegration.countItauBank > 0)
        {
            ul += `<ul style="text-align: justify;">`;
            texto_delete_cloud = nameIntegration.countApiBoletoCloud > 0 ? `<li><span style="padding: 3px;border-radius: 3px;">Há pelo menos <strong><u>${nameIntegration.countApiBoletoCloud}</u> </strong> boletos do bradesco selecionados </span></li>` : '';
            texto_delete_itau_bank = nameIntegration.countItauBank > 0 ? `<li><span style="padding: 3px;border-radius: 3px;">Há pelo menos <strong><u>${nameIntegration.countItauBank}</u> </strong> boletos do ITAÚ selecionados </span></li>` : '';
            ul += texto_delete_cloud;
            ul += texto_delete_itau_bank;
            ul += `</ul>`;
            texto_atencao = `<p><div style="text-align: center;"><strong>Atenção!</strong></div></p>`;
            texto_principal = `<p>
                                    <div style="text-align: justify;">
                                        Somente boletos do banco <strong><u>Itaú</u></strong> sofrerão as alterações de forma simultânea no banco via API. 
                                        Todos os outros deverão ser alterados também no aplicativo do respectivo banco. 
                                    </div>
                                </p>`;
            texto_observacao = `<p><div style="text-align: justify;">Obs.: Apenas boletos do Itaú serão alterados automaticamente, caso contrário, favor dar a baixa também no aplicativo do banco. </div></p>`;
        }
        
        Swal.fire({
            icon: "info",
            // title: "Atenção!",
            html: `<div>
                    <p style="padding: 3px;"> <span style="background: #f5f0ff;padding: 3px;border-radius: 3px;">
                        Deseja deletar as linhas selecionadas? Esta ação não poderá ser desfeita.
                    </p>
                </div>
                
                ${texto_atencao}
                ${ul}
                ${texto_principal}
                ${texto_observacao}
            
            `,
            width: '800px',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed)
            {
                handlerDelete();
            }
        });
    }

    /* deleta as linhas selecionada em massa*/
    const handlerDelete = async () => {
        
        if (auth && auth.api_token)
        {
            const functionName = funDeleteInMass;
            if (functionName && functionName in functionMap)
            {
                const func = functionMap[funDeleteInMass as keyof typeof functionMap];
                if (func)
                {
                    const response = await func(selectedRows, auth);
                    
                    if(response.data.success)
                    {
                        Swal.fire({
                            icon: "success",
                            title: "Parabéns",
                            text: `${textDelete || 'Linhas'} deletadas com sucesso`,
                            showCancelButton: false,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Entendi',
                        }); 
                        
                        fetchData();
                    }else{

                        if(response.data.status && response.data.status !== 403)
                        {
                            Swal.fire({
                                icon: "info",
                                title: "Atenção",
                                html: `Não foi possível deletar as  linhas selecionadas, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                                showCancelButton: false,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'Entendi',
                            });  
                        }
                        
                    }

                    setSelectedRows([]);
                    var checkboxes = document.getElementsByClassName("form-check-input");
                    for (var i = 0; i < checkboxes.length; i++)
                    {
                        var checkbox = checkboxes[i] as HTMLInputElement;
                        checkbox.checked = false;
                        
                    }
                }
            }

        }else{
            Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Necessário fazer novo login",
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Entendi',
            });
        }
    }

    const fetchData = async () => {
        setLoading(true);

        let FILTER:any = {
            ...filterForm,
            ...filterGlobalProduct
        }

        type Method = 'get' | 'post' | 'put' | 'delete' | 'patch' | 'head';
        interface ICustomConfig {
            method: Method;
            url: string;
            data?: {
              page: number;
              pageSize: number;
              id?: string | number;
              formFilter?: any;
            };
            
        }

        const customConfig:ICustomConfig = {
            method: methodEndPoint === 'post' ? 'post' : 'get',
            url: `/module/${endPoint}`,
            data: {
                page: pageIndex,
                pageSize: pageSize,
                id: id,
                formFilter: methodEndPoint === 'post' ? FILTER : undefined,
            },
            
        };

        const http = BaseServiceHttp();
        const result = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            setResultFetch({
                                status: 200,
                            });
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            if(error.response && error.response.data)
                            {
                                setResultFetch(error.response.data);
                            }
                            return {
                                data:{
                                    totalRecords: 0,
                                    currentPage: 0,
                                    pageSize: 0,
                                    records: []
                                }
                            }
                        }); 

        setData(result.data.records);
        setTotalCount(result.data.totalRecords);
        setLoading(false);
        
        setProposPaginate(
            {
                gotoPage,
                previousPage,
                nextPage,
                page,
                totalCount,
                pageCount,
                disabledPrevious, 
                disabledNext
            }
        );

    };  
    

    useEffect(() => {
        fetchData();

    }, [endPoint, filterForm, filterGlobalProduct]);


    useEffect(() => {
        if (reloadTable)
        {
            fetchData();          
        }
    }, [reloadTable]);

    useEffect(() => {
        if (
            tableData && tableData.length > 0 &&
            tableData[0].hasOwnProperty('id') && tableData[0].id !== '0' && tableData[0].id !== 0 && tableData[0].id !== ''
        )
        {
            updateItem()
        }else{
            if( tableData && tableData.length > 0 )
            {
                setTotalCount(data.length + 1)
                handleInsertInTable(tableData[0][tableData.length - 1]);
            }
        }

    }, [tableData, totalCount, filterForm]);

    
    const dispatch = useDispatch();
    useEffect(()=> {
        if(Object.keys(resultFetch).length > 0)
        {
            dispatch(setPermission(resultFetch))
        }

    }, [dispatch, resultFetch])

    // Função para atualizar os valores de um item específico com base no ID
    const updateItem = () => {
        setData(prevData => {
            const newData = prevData.map(dataItem => {
                const tableItem = tableData.find((item: { id: string | number; }) => item.id === dataItem.id);
            
                // Atualiza as propriedades dinamicamente
                if (tableItem) {
                    tableItem.value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseInt(tableItem.value));
                    const updatedItem = Object.assign({}, dataItem, tableItem);
                    return updatedItem;
                }
            
                return dataItem;
            });

            // Adicione o item ao estado se não existir
            tableData.forEach((tableItem: { id: any, value:any }) => {
                const exists = newData.some(dataItem => dataItem.id === tableItem.id);
                if (!exists) {
                    // tableItem.value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseInt(tableItem.value));
                    newData.push(tableItem);
                }
            });
          
            return newData;
        });
    };

    // Função para atualizar os valores de um item específico com base no ID
    const handleInsertInTable = (newItem: ItemTypeParcel) => {
        setData(prevData => [...prevData, newItem]);
    };
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { selectedRowIds, pageIndex, pageSize },
        
        } = useTable<typeof interfaceDinamic>(
        {
            columns,
            data,
            
            initialState: { pageIndex: 0, pageSize: 10 }
        },
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps, rows } ) => {
                    
                    const handleToggleAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
                        const checked = event.target.checked;
                        let selectableRowIds;

                        // somente libera chechar os boletos caso for pagamento spara alterar status
                        if(iInterface !== 'installment')
                        {

                            selectableRowIds = rows
                            .filter(row => {
                                // const isPaid = (row.original.status === "Pago") || (!!row.original?.token_boleto);
                                const isPaid = (row.original.status === "1" || row.original.status === "2");
                                return !isPaid;
                            })
                            .map(row => String(row.original.id));
                        }else{
                            selectableRowIds = rows
                                                .filter(row => {
                                                    const isPaid = (row.original.status === "1" || row.original.status === "2") || (!!row.original?.token_boleto);
                                                    // const isPaid = (row.original.status === "Pago");
                                                    return !isPaid;
                                                })
                                                .map(row => String(row.original.id));
                            
                        }
                        
                        setSelectedRows(checked ? selectableRowIds : []);
                        
                        // somente libera chechar os boletos caso for pagamento spara alterar status
                        if(iInterface !== 'installment')
                        {
                            rows.forEach(row => {
                                // const isPaid = (row.original.status === "Pago") || (!!row.original?.token_boleto);
                                const isPaid = (row.original.status === "1" || row.original.status === "2");
                                if (!isPaid) {
                                    row.toggleRowSelected(checked);
                                }
                            });
                        }else{
                            
                            rows.forEach(row => {
                                const isPaid = (row.original.status === "1" || row.original.status === "2") || (!!row.original?.token_boleto);
                                // const isPaid = (row.original.status === "Pago");
                                if (!isPaid) {
                                    row.toggleRowSelected(checked);
                                }
                            });                            
                        }
                        
                    };

                    if(isHideChekbox )
                    {
                        return (
                            <KTSVG path="/media/icons/duotune/arrows/arr015.svg"/>
                        )
                    }else{
                        return (
                            <div id="checkbox" className="form-check form-check-sm form-check-custom form-check-solid">
                                
                                {/* seleciona todos */}
                                <IndeterminateCheckbox 
                                    {...getToggleAllRowsSelectedProps()} 
                                    className="form-check-input widget-9-check"
                                    onChange={handleToggleAllRows}
                                    
                                />
                            </div>
                        
                        )
                    }
                    
              },
                // Cell: ({ row }: { row: Row<typeInterfaceDinamic>}, index:number) => {
                Cell: ({ row }: { row: Row<any>}, index:number) => {

                    // const isPaid = row.original.status === 'Pago';
                    const hasBoleto = row.original.hasOwnProperty("token_boleto") && row.original.token_boleto !== ''  && row.original.token_boleto !== null;
                    // const name_integration = row.original.hasOwnProperty("name_integration") && !!row.original?.name_integration ?  row.original?.name_integration : '';
                    const hasDeleted = row.original.hasOwnProperty("deleted") && row.original.deleted !== ''  && row.original.deleted !== null;
                    if(hasDeleted)
                    {
                        return (<KTSVG className="svg-icon svg-icon-2x svg-icon-danger ms-n3" path={'/media/icons/duotune/general/gen027.svg'}/>);
                    }if(row.original.status === "1" || row.original.status === "2"){
                        return (<KTSVG className="svg-icon svg-icon-2x svg-icon-success ms-n3" path={'/media/icons/duotune/arrows/arr086.svg'}/>);
                    }else if(hasBoleto)
                    {
                        return (
                                <>
                                    {
                                        
                                        iInterface === 'installment' 
                                        ? (
                                            <div key={`installment${index}`} className="form-check form-check-sm form-check-custom form-check-solid">
                                                {/* seleciona individual */}
                                                <IndeterminateCheckbox 
                                                    {...row.getToggleRowSelectedProps()} 
                                                    className="form-check-input widget-9-check"
                                                    onChange={(e) => handlerSelectedRow(row, e)}
                                                />
                                                {
                                                    row.original.hasOwnProperty("name_integration") && !!row.original?.name_integration && row.original?.name_integration === 'itau_bank' && !!row.original?.link_boleto
                                                        ? ( <> <img style={{
                                                                        width: "29px",
                                                                        borderRadius: "3px",
                                                                        marginLeft: "6px"
                                                                    }}
                                                                    src={`/media/icons/banks/itau/itau_unibanco.png`}/></>) 
                                                        : row.original.hasOwnProperty("name_integration") && !!row.original?.name_integration && row.original?.name_integration === 'api_cloud' && !!row.original?.link_boleto
                                                        ? ( <> <img style={{
                                                                        width: "38px"
                                                                    }} 
                                                                    src={`/media/icons/banks/bradesco/bradesco.png`}/></>) // width: 22px; margin-left: 7px;  
                                                        : ( <></>) 
                                                    }

                                                {/* <KTSVG className="svg-icon svg-icon-2x svg-icon-danger ms-3" path="/media/icons/duotune/ecommerce/ecm010.svg"/> */}
                                            </div>
                                        )
                                        :(
                                            <>
                                                {
                                                    row.original.hasOwnProperty("name_integration") && !!row.original?.name_integration && row.original?.name_integration === 'itau_bank' && !!row.original?.link_boleto
                                                    ? ( <> <img style={{
                                                                    width: "29px",
                                                                    borderRadius: "3px",
                                                                    marginLeft: "6px"
                                                                }}
                                                                src={`/media/icons/banks/itau/itau_unibanco.png`}/></>) 
                                                    : row.original.hasOwnProperty("name_integration") && !!row.original?.name_integration && row.original?.name_integration === 'api_cloud' && !!row.original?.link_boleto
                                                    ? ( <> <img style={{
                                                                    width: "38px"
                                                                }} 
                                                                src={`/media/icons/banks/bradesco/bradesco.png`}/></>) // width: 22px; margin-left: 7px;  
                                                    : ( <></>) 
                                                }
                                            </>
                                            // <KTSVG className="svg-icon svg-icon-2x svg-icon-danger ms-n5 " path="/media/icons/duotune/ecommerce/ecm010.svg"/>
                                        )

                                    }
                                </>
                        );
                    }else{
                        if(isHideChekbox )
                        {
                            return (
                                <KTSVG path="/media/icons/duotune/arrows/arr015.svg"/>
                            )
                        }else{
                            return (
                                <div key={index} className="form-check form-check-sm form-check-custom form-check-solid">
                                    {/* seleciona individual */}
                                    <IndeterminateCheckbox 
                                        {...row.getToggleRowSelectedProps()} 
                                        className="form-check-input widget-9-check"
                                        onChange={(e) => handlerSelectedRow(row, e)}
                                    />
                                </div>
                            )
                        }
                    }
                    
                },
              },
              ...columns,
            ]);
        }

    );

    const disabledPrevious = canPreviousPage !== true ? 'disabled' : '';
    const disabledNext = canNextPage !== true ? 'disabled' : '';

    return (
            <div className="flex-lg-row-fluid ">
                <div className="card">           
                    <div className="card-header align-items-center border-0 py-5 gap-5">
                        <div className="card-title">
                            <h2 className="fw-bold">{title}</h2>
                        </div>
                        

                        <div className="d-flex align-items-center">
                            <ComponentPermission includeVerify={verifyPermission ? [verifyPermission] : undefined}>
                                {
                                    download && typeof download === 'function' ? (
                                        <div className="fw-bold row me-7 mt-4 w-110px">
                                            <ComponentPermission.Export equalVerify={verifyPermission}>
                                                <button
                                                    className="btn btn-sm btn-light"
                                                    onClick={() => download()}
                                                >
                                                    Exportar em Excel
                                                </button>
                                            </ComponentPermission.Export>
                                        </div>
                                    ):(<></>)
                                    // <>

                                    //     <a href="#" className="btn btn-sm btn-flex fw-bold bg-body btn-color-gray-700 btn-active-color-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    //         Exportação <KTSVG path={"/media/icons/duotune/general/gen031.svg"}/>
                                    //     </a>
                                                                
                                    //     <MenuButton buttons={['ButtonExcel']}/>
                                    // </>
                                }

                                {
                                    handleInsert && typeof handleInsert === 'function' && (
                                        
                                        <div className="fw-bold row me-1 mt-9 w-110px">
                                            
                                            <ComponentPermission.Create >
                                                <button
                                                    className="btn btn-sm btn-light"
                                                    onClick={() => handleInsert()}
                                                >
                                                    Inserir novo
                                                </button>
                                            </ComponentPermission.Create>

                                        
                                        </div>
                                    )
                                }
                                        
                                {
                                    selectedRows.length > 0 && funDeleteInMass && funDeleteInMass in functionMap && (
                                        
                                        <div className="fw-bold fs-8 me-n12 mt-4 row w-150px">
                                            
                                            <ComponentPermission.Delete equalVerify={verifyPermission}>
                                                <div>
                                                    <span className="ms-n1" >{selectedRows.length} Selecionado(s)</span>
                                                    <button type="button" className="btn btn-sm btn-danger ms-n2 w-100px" onClick={() => handlerConfirmeDelete()}>
                                                        Deletar
                                                    </button>
                                                </div>
                                            </ComponentPermission.Delete>
                                        </div>
                                        
                                    )
                                } 
                            
                                { selectedRows.length > 0 && optionsActionInMassa && optionsActionInMassa.length > 0 &&(
                                    <div className="fw-bold row me-1 w-200px">
                                        
                                        <ComponentPermission.Edit equalVerify={verifyPermission}>
                                            <div>
                                                <label className='form-label fw-bold'>Alterar em massa para: {selectedRows.length}</label>
                                                <div className='d-flex'>
                                                    <Select
                                                        className="w-450px"
                                                        placeholder=""
                                                        value={changeInMass  }
                                                        onChange={(selectedValue) => {
                                                            
                                                                const newValue = selectedValue ? { value: selectedValue.value, field: selectedValue.field, label: selectedValue.label } : { value: '', field: '', label: 'Nenhum' };
                                                                setChangeInMass([newValue]);
                                                            
                                                                handlerConfirmeChangeInMass();
                                                            }
                                                        }
                                                        options={optionsActionInMassa}
                                                    />
                                                </div>
                                            </div>
                                        </ComponentPermission.Edit>
                                    </div>
                                )}
                                     
                            
                            </ComponentPermission>                            

                            <div className="d-flex align-items-center pagination">
                                <div className="dataTables_length mt-9 me-3" id="">
                                    <label>
                                    
                                        <select name="" className="form-select form-select-sm form-select-solid"
                                            value={pageSize || 1}
                                            onChange={e => {
                                                setPageSize(Number(e.target.value))
                                            }}
                                        >
                                            {[10, 20, 30, 40, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                            Exibir {pageSize}
                                            </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                                <div>
                                    <span className="fw-semibold text-muted me-2 mt-2">
                                        Página {pageOptions.length > 0 ? (pageIndex + 1) : pageIndex } de {pageOptions.length}                                        
                                    </span>
                                    <div className="form-floating mt-3 me-3 ">
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            id="floatingInput"
                                            value={pageOptions.length > 0 ? (pageIndex + 1) : pageIndex}
                                            
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(page)
                                            }}
                                            min="1"
                                            max={pageOptions.length || 1}
                                            placeholder="Ir para página"
                                            style={{height:'31px',width:'8.8rem'}}
                                        />
                                        <label htmlFor="floatingInput" style={{marginTop:'-0.3rem'}}>Ir para página</label>
                                    </div>
                                </div>

                                <div>
                                    <span className="ms-3 badge badge-light-success fw-bold px-4 py-3"> Total de {totalCount} Registro(s) </span>
                                    <div className="d-flex d-flex-column">
                                        { !isHidePaginate && (
                                        
                                            <>
                                                <li className={`${disabledPrevious} page-item previous mt-2`}  >
                                                    <a type="button" onClick={() => gotoPage(0)}  className="page-link">
                                                        <KTSVG path="/media/icons/duotune/arrows/arr021.svg" className="svg-icon-muted svg-icon-1hx" />
                                                    </a>
                                                    {' '}
                                                </li>
                                                <li className={`${disabledPrevious} page-item previous mt-2`}>
                                                    <a type="button" onClick={() => previousPage()} className="page-link" >
                                                        <i className="previous"></i>
                                                    </a>
                                                    {' '}
                                                </li>
                                                <li className={`${disabledNext} page-item next mt-2`}>
                                                    <a type="button" onClick={() => nextPage()} className="page-link">
                                                        <i className="next"></i>
                                                    </a>
                                                    {' '}
                                                </li>
                                                <li className={`${disabledNext} page-item next mt-2`}>
                                                    <a type="button" onClick={() => gotoPage(pageCount - 1)} className="page-link">
                                                        <KTSVG path="/media/icons/duotune/arrows/arr024.svg" className="svg-icon-muted svg-icon-1hx" />
                                                        
                                                    </a>
                                                    {' '}
                                                </li>
                                            </>
                                        )}
                                    </div>
                                </div>
                                
                                {/*  */}
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="flex-wrap gap-2 justify-content-between mb-8">                                        
                        <div className="table-responsive table-loading">
                            <table {...getTableProps()} id="tableAdvanced" className="table table-row-dashed border gy-4 gs-4">
                                <thead className="">
                                    {
                                        headerGroups.map((headerGroup:any, index:any) => (

                                            <tr {...headerGroup.getHeaderGroupProps()} key={`header_${index}`} className="fw-semibold  fs-6 text-gray-800 border-bottom border-gray-200">
                                                {
                                                    headerGroup.headers.map((column:any, position:any) => {

                                                        return (
                                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`sorting ${classHeadTd}`}>
                                                            {column.render("Header")}
                                                            
                                                            {
                                                                column.id !=='selection' && column.Header !== 'Ações' ? (
                                                                    column.isSorted
                                                                        ? column.isSortedDesc
                                                                            ? <KTSVG path="/media/icons/duotune/arrows/arr072.svg"/>
                                                                            : <KTSVG path="/media/icons/duotune/arrows/arr073.svg"/>
                                                                        : <KTSVG path="/media/icons/duotune/arrows/arr072.svg"/>
                                                                    
                                                                ):
                                                                (<></>)
                                                            }
                                                            

                                                        </th>
                                                    )})
                                                }
                                            </tr>
                                        ))
                                    }
                                </thead>
                                <tbody {...getTableBodyProps()} className="min-w-100px sorting">
                                {loading ? (
                                    <tr id="table_loading">
                                        <td>
                                            <div className="table-loading-message">
                                            Carregando...
                                        </div>
                                        </td>
                                    </tr>
                                ) : rows.length > 0 ? (
                                    page.map((row, i) => {
                                        prepareRow(row);

                                        let classBgExpirate = '';
                                        let classTrExpirate = '';

                                        let classHasDeleted = '';

                                        
                                        if(row.original.hasOwnProperty('due_date') && !!row.original.due_date){
                                            if(row.original.hasOwnProperty('status') && !!row.original.status)
                                            {
                                                if(row.original?.status === '0')
                                                {
                                                    classBgExpirate = isDateExpired(row.original.due_date) ? 'bg-opacity-15 bg-danger text-danger' : '';
                                                    classTrExpirate = isDateExpired(row.original.due_date) ? '' : 'text-muted';
                                                }else if( row.original?.status === '2')
                                                {
                                                    classBgExpirate = isDateExpired(row.original.due_date) ? 'bg-opacity-15 bg-warning text-warning' : '';
                                                    classTrExpirate = isDateExpired(row.original.due_date) ? '' : 'text-muted';
                                                }
                                            }
                                        }
                                        
                                        if(row.original.hasOwnProperty('deleted') && row.original.deleted !== ''  && row.original.deleted !== null)
                                        {
                                            classHasDeleted = 'fst-italic fw-bolder text-danger text-decoration-line-through';
                                            classTrExpirate = '';
                                        }


                                        return (
                                            <tr {...row.getRowProps()} 
                                                    key={`row_${row.original.id}_${i}`} 
                                                    row-id={row.original.id} 
                                                    className={`text-start gs-0 ${classtbodyTr} ${classTrExpirate} ${classBgExpirate} ${classHasDeleted}`}
                                                    >
                                                {
                                                    row.cells.map((cell) => {
                                                        const _isArray = Array.isArray(cell.value);                                               
                                                        return _isArray 
                                                                ? (
                                                                    <td>
                                                                        <div className="d-flex align-items-center" id={`child_${row.original.id}_${i}`}>
                                                                            <div className="d-flex justify-content-start flex-column">
                                                                                <label className="text-gray-400 fw-semibold d-block fs-7">{cell.value[0] || '...'}</label>
                                                                                <div className="fw-normal text-gray-600">
                                                                                    {
                                                                                        // ('idLink' in row.original ) ? 
                                                                                        //     <Link  to={`/${row.original.idLink}`}>
                                                                                        //         {cell.value[1] || '...'}
                                                                                        //     </Link>
                                                                                        //     : 
                                                                                        //     <Link  to={`#`}>
                                                                                        //         {cell.value[1] || '...'}
                                                                                        //     </Link>

                                                                                            <Link  to={`${row.original.link}`}>
                                                                                                {cell.value[1] || '...'}
                                                                                            </Link>
                                                                                    }
                                                                                
                                                                                    {/* <a href={`/contratos/contract/${row.original.idUnidade}`} className=""></a> */}
                                                                                </div>
                                                                            </div> 
                                                                        </div>
                                                                    </td>

                                                                ):(
                                                                    <td className={`${classTbodyTd}`} {...cell.getCellProps()}>
                                                                            {cell.render("Cell") || '...'}
                                                                    </td>
                                                                    );
                                                    })
                                                }
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr key={100000000000000} className=" form-row text-start text-muted gs-0" id="table_no_data">
                                        <td colSpan={columns.length} className="text-center">Nenhum dado disponível</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                </div>
            </div>
    );
};


export default TableAdvanced;
