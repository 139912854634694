
export const formatarValor = (value: any) => {

    if (isNaN(value))
    {
        return parseFloat(value.replace(/\./g,'').replace(/\,/g,'.'));
    }
    return value;
};

export const convertMoedaToFloat = (value: any) =>{

    // Remover caracteres não numéricos, exceto o ponto para casas decimais
    const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));

    // Se o valor não for um número válido, retornar 0
    return isNaN(numericValue) ? 0 : numericValue;

 }

export const formatarPercentage = (value: any) => {
    
    var newValue = value;
    newValue = newValue + '';
    newValue = parseInt(newValue.replace(/[\D]+/g,''));
    if (isNaN(newValue))
    {
        return `0,00 %`;
    }

    newValue = newValue + '';
    newValue = newValue.replace(/([0-9]{2})$/g, ",$1");

    if (newValue.length > 6)
    {
        newValue = newValue.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    newValue = `${newValue} %`;

    return newValue;

};
